
import React from 'react'



const SvgComponent = props => (
<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="37.5" cy="37.5" r="37.5" fill="#F30000"/>
<path d="M16.0713 39.1816C16.0713 39.7572 15.9253 40.2692 15.6333 40.7178C15.3413 41.1621 14.9202 41.5091 14.3701 41.7588C13.82 42.0042 13.1746 42.127 12.4341 42.127C11.8162 42.127 11.2979 42.0846 10.8789 42C10.46 41.9111 10.0241 41.7588 9.57129 41.543V39.3086C10.0495 39.554 10.5467 39.7466 11.063 39.8862C11.5793 40.0216 12.0532 40.0894 12.4849 40.0894C12.8573 40.0894 13.1302 40.0259 13.3037 39.8989C13.4772 39.7677 13.564 39.6006 13.564 39.3975C13.564 39.2705 13.528 39.1605 13.4561 39.0674C13.3883 38.9701 13.2762 38.8727 13.1196 38.7754C12.9673 38.6781 12.5568 38.4792 11.8882 38.1787C11.283 37.9036 10.8281 37.637 10.5234 37.3789C10.223 37.1208 9.9987 36.8245 9.85059 36.4902C9.70671 36.1559 9.63477 35.7603 9.63477 35.3032C9.63477 34.4484 9.9458 33.7819 10.5679 33.3037C11.1899 32.8255 12.0448 32.5864 13.1323 32.5864C14.0929 32.5864 15.0726 32.8086 16.0713 33.2529L15.3032 35.189C14.4357 34.7912 13.6867 34.5923 13.0562 34.5923C12.7303 34.5923 12.4933 34.6494 12.3452 34.7637C12.1971 34.8779 12.123 35.0197 12.123 35.189C12.123 35.3709 12.2161 35.5339 12.4023 35.6777C12.5928 35.8216 13.1048 36.084 13.9385 36.4648C14.7383 36.8245 15.2926 37.2118 15.6016 37.6265C15.9147 38.0369 16.0713 38.5553 16.0713 39.1816Z" fill="white"/>
<path d="M24.228 38.3882C24.228 39.5646 23.9106 40.4829 23.2759 41.1431C22.6453 41.799 21.7609 42.127 20.6226 42.127C19.5308 42.127 18.6611 41.7905 18.0137 41.1177C17.3704 40.4448 17.0488 39.535 17.0488 38.3882C17.0488 37.216 17.3641 36.3062 17.9946 35.6587C18.6294 35.007 19.5181 34.6812 20.6606 34.6812C21.3674 34.6812 21.9915 34.8314 22.5332 35.1318C23.0749 35.4323 23.4917 35.8639 23.7837 36.4268C24.0799 36.9854 24.228 37.6392 24.228 38.3882ZM19.5688 38.3882C19.5688 39.006 19.6514 39.4779 19.8164 39.8037C19.9814 40.1253 20.2586 40.2861 20.6479 40.2861C21.033 40.2861 21.3039 40.1253 21.4604 39.8037C21.6213 39.4779 21.7017 39.006 21.7017 38.3882C21.7017 37.7746 21.6213 37.3112 21.4604 36.998C21.2996 36.6849 21.0246 36.5283 20.6353 36.5283C20.2544 36.5283 19.9814 36.6849 19.8164 36.998C19.6514 37.307 19.5688 37.7703 19.5688 38.3882Z" fill="white"/>
<path d="M28.1318 42H25.6499V32.123H28.1318V42Z" fill="white"/>
<path d="M32.0864 42.127C31.5744 42.127 31.1258 41.981 30.7407 41.689C30.3599 41.397 30.0636 40.9674 29.8521 40.4004C29.6447 39.8333 29.541 39.1711 29.541 38.4136C29.541 37.2456 29.7759 36.3315 30.2456 35.6714C30.7153 35.0112 31.3607 34.6812 32.1816 34.6812C32.5837 34.6812 32.9349 34.7616 33.2354 34.9224C33.54 35.0832 33.8172 35.354 34.0669 35.7349H34.1177C34.0373 35.172 33.9971 34.6092 33.9971 34.0464V32.123H36.498V42H34.6255L34.0923 41.0923H33.9971C33.5697 41.7821 32.9328 42.127 32.0864 42.127ZM33.1021 40.1655C33.4998 40.1655 33.7791 40.0407 33.9399 39.791C34.105 39.5413 34.1938 39.1562 34.2065 38.6357V38.439C34.2065 37.8042 34.1156 37.3535 33.9336 37.0869C33.7516 36.8161 33.466 36.6807 33.0767 36.6807C32.7508 36.6807 32.4969 36.8351 32.3149 37.144C32.1372 37.4487 32.0483 37.8846 32.0483 38.4517C32.0483 39.0103 32.1393 39.4355 32.3213 39.7275C32.5033 40.0195 32.7635 40.1655 33.1021 40.1655Z" fill="white"/>
<path d="M50.4248 37.3472C50.4248 38.9087 50.0418 40.0957 49.2759 40.9082C48.5099 41.7207 47.3885 42.127 45.9116 42.127C44.4559 42.127 43.3387 41.7186 42.5601 40.9019C41.7856 40.0851 41.3984 38.896 41.3984 37.3345C41.3984 35.7899 41.7835 34.6113 42.5537 33.7988C43.3281 32.9821 44.4517 32.5737 45.9243 32.5737C47.4012 32.5737 48.5205 32.9779 49.2822 33.7861C50.0439 34.5944 50.4248 35.7814 50.4248 37.3472ZM44.0391 37.3472C44.0391 39.1414 44.6632 40.0386 45.9116 40.0386C46.5464 40.0386 47.0161 39.8206 47.3208 39.3848C47.6297 38.9489 47.7842 38.2697 47.7842 37.3472C47.7842 36.4204 47.6276 35.737 47.3145 35.2969C47.0055 34.8525 46.5422 34.6304 45.9243 34.6304C44.6675 34.6304 44.0391 35.536 44.0391 37.3472Z" fill="white"/>
<path d="M57.0771 42L56.7661 41.105H56.6201C56.4128 41.4351 56.1229 41.689 55.7505 41.8667C55.3823 42.0402 54.9486 42.127 54.4492 42.127C53.6579 42.127 53.0422 41.8984 52.6021 41.4414C52.1619 40.9801 51.9419 40.3306 51.9419 39.4927V34.8081H54.4238V38.7373C54.4238 39.207 54.4894 39.5625 54.6206 39.8037C54.756 40.0449 54.9761 40.1655 55.2808 40.1655C55.7082 40.1655 56.0129 39.9984 56.1948 39.6641C56.3768 39.3255 56.4678 38.7563 56.4678 37.9565V34.8081H58.9497V42H57.0771Z" fill="white"/>
<path d="M64.1865 40.1655C64.5208 40.1655 64.9207 40.083 65.3862 39.918V41.7271C65.0519 41.8709 64.7324 41.9725 64.4277 42.0317C64.1273 42.0952 63.7739 42.127 63.3677 42.127C62.534 42.127 61.9331 41.9238 61.5649 41.5176C61.1968 41.1071 61.0127 40.4787 61.0127 39.6323V36.668H60.1431V35.6587L61.2476 34.8779L61.8887 33.3672H63.5137V34.8081H65.2783V36.668H63.5137V39.4673C63.5137 39.9328 63.738 40.1655 64.1865 40.1655Z" fill="white"/>
</svg>

)

export default SvgComponent